import React from "react";
import "assets/scss/navbar.scss"
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 49 ||
        document.body.scrollTop > 49
      ) {
        setNavbarColor("bg-danger");
      } else if (
        document.documentElement.scrollTop < 50 ||
        document.body.scrollTop < 50
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="https://demos.creative-tim.com/now-ui-kit-react/#/index?ref=nukr-index-navbar"
              target="_blank"
              id="navbar-brand"
            >
              MITUTSO
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              Уникални решения
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="#home"
                  onClick={e => {
                    e.preventDefault();
                    document
                      .getElementById("header")
                      .scrollIntoView();
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(!collapseOpen);
                  }}
                >
                  <i className="now-ui-icons business_bank"></i>
                  <p>Начало</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#products+services"
                  onClick={e => {
                    e.preventDefault();
                    document
                      .getElementById("products_services")
                      .scrollIntoView();
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(!collapseOpen);
                  }}
                >
                  <i className="now-ui-icons business_briefcase-24"></i>
                  <p>Продукти и услуги</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#feedback"
                  onClick={e => {
                    e.preventDefault();
                    document
                      .getElementById("feedback")
                      .scrollIntoView();
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(!collapseOpen);
                  }}
                >
                  <i className="now-ui-icons files_single-copy-04"></i>
                  <p>Другите за нас</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#contacts"
                  onClick={e => {
                    e.preventDefault();
                    document
                      .getElementById("contact")
                      .scrollIntoView();
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(!collapseOpen);
                  }}
                >
                  <i className="now-ui-icons business_badge"></i>
                  <p>Контакти</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.linkedin.com/in/mitutso/"
                  target="_blank"
                  id="linkedin-tooltip"
                >
                  <i className="fab fa-linkedin-in"></i>
                  <p className="d-lg-none d-xl-none">Mitutso</p>
                </NavLink>
                <UncontrolledTooltip target="#linkedin-tooltip">
                  Последвайте ни в Linkedin
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
