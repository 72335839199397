import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function Feedback() {
    return (
        <div id="feedback" className="section section-feedback text-center">
            <Container>
                <h2 className="title">Другите за нас</h2>
                <div className="feedback">
                    <Row>
                        <Col sm="12" md={{ size: 6, offset: 3 }}>
                            <div className="feedback-client">
                                <h4 className="client">Евгени Петров</h4>
                                <p className="category text-danger">
                                    собственик на 4-стаен апартамент в квартал Стрелбище, 
                                    спечелил награда на списание Идеален Дом и луксозно завършен от екипа на фирмата
                                </p>
                                <p className="description" style={{fontWeight: "inherit"}}>
                                    Когато повериш на някого да изгражда и довършва 
                                    собственото ти жилище би искал да работиш с такъв екип, 
                                    с когото да можеш да забравяш, че този проект съществува. 
                                    През това време можех да се отдам на управлението на бизнеса си 
                                    и да не мисля за ремонта в апартамента. Екипа, управляван от 
                                    Цветан Митуцов организираше цялата логистика и координация и бяха 
                                    отговорни за завършването на всеки един детайл. Не само се нанесохме в 
                                    един чудесно завършен апартамент, но също така продължихме да разчитаме 
                                    на екипа за поддръжката на апартамента и след това. Нашата съвместна работа 
                                    продължи и с още два офиса на обща площ почти 1000 кв.м. и резултата беше 
                                    отново отлично и отговорно свършена работа. С радост бихме им се доверили 
                                    отново и за други проекти за в бъдеще!
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Feedback;