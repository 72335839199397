import React from 'react';
import StackGrid from "react-stack-grid";

function getData() 
{
  let data = [];

  for (let i = 39; i > 0; i--)
  {
    data.push(
      {
        img: require("assets/img/grid/grid_" + i + ".jpg"),
        title: 'Mitutso Design',
        alt: 'Interior design by Mitutso'
      }
    )
  }

  return data;
}

class Grid extends React.Component {
  render() {
    return (
      <StackGrid
        columnWidth={350}
        monitorImagesLoaded={true}
      >
        {getData().map((gridObject, index) => (
          <img key={index} src={gridObject.img} title={gridObject.title} alt={gridObject.alt} />
        ))}
      </StackGrid>
    );
  }
}

export default Grid;