import React from "react";
import "assets/scss/products_services.scss";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function ProductsServices() {
    const bullets = {
        fontWeight: "inherit",
    }

    return (
        <>
            <div id="products_services" className="section section-products-services">
                <Container>
                    <img
                        title="Mitutso Company Logo"
                        alt="Mitutso is company focused in interior design."
                        className="n-logo"
                        src={require("assets/img/mitutso-logo-full.svg")}
                    />
                    <div className="separator separator-primary"></div>
                    <div className="section-story-overview">
                        <Row>
                            <Col md="6">
                                <div
                                    className="image-container image-left image-products-services-left"
                                    style={{
                                    backgroundImage:
                                        "url(" + require("assets/img/products_services/bg_2.jpg") + ")"
                                    }}
                                />
                                <div
                                    className="image-container image-products-services-middle"
                                    style={{
                                    backgroundImage:
                                        "url(" + require("assets/img/products_services/bg_3.jpg") + ")"
                                    }}
                                />
                            </Col>
                            <Col md="5" of="true">
                                <div
                                    className="image-container image-right image-products-services-right"
                                    style={{
                                    backgroundImage:
                                        "url(" + require("assets/img/products_services/bg_1.jpg") + ")"
                                    }}
                                ></div>
                                <h3>
                                    Продукти и Услуги
                                </h3>
                                <p style={bullets}>
                                    Луксозно и прецизно организиране на довършителните дейности за Вашия дом или офис.
                                </p>
                                <p style={bullets}>
                                    Консултиране по всяка една стъпка или дейност, свързани с обзавеждане, ремонт или избор на дизайн.
                                </p>
                                <p style={bullets}>
                                    Бутиково проектиране и изработване на различни интериорни детайли.
                                </p>
                                <p style={bullets}>
                                    Проектиране и дизайн на цялостно решение за климатизация и осветление.
                                </p>
                                <p style={bullets}>
                                    Дългосрочен ангажимент за управление, развитие и поддържане на всеки един от нашите завършение проекти.
                                </p>
                                <p style={bullets}>
                                    Проектиране и цялостно изработване на мебели, съобразени с особеностите на всеки сантиметър от обектите на работа.
                                </p>
                            </Col>
                            <Col sm="12" md={{ size: 6, offset: 3 }}>
                                <div
                                    className="image-container image-right"
                                    style={{
                                    backgroundImage:
                                        "url(" + require("assets/img/products_services/bg_4.jpg") + ")"
                                    }}
                                ></div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default ProductsServices;