import React from "react";
import {
    Container
} from "reactstrap";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    width: 'auto',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function CardComponent() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
        <CardContent>
            <Typography variant="h4" component="h4" style={{paddingBottom: "20px"}}>
                Цветан Митуцов
            </Typography>
            <Typography variant="h5" component="h4">
                +359 896 606 196
            </Typography>
            <Typography variant="h5" component="h4">
                tm@mitutso.com
            </Typography>
            <Typography className={classes.pos} color="textSecondary">
                Адрес: София 1164, ул. Златовръх 5, офис 4
            </Typography>
        </CardContent>
    </Card>
  );
}

class Contact extends React.Component
{
    constructor(props)
    {
        super(props);
        
        this.state = {
            firstFocus: false,
            lastFocus: false
        }
        
        this.setFirstFocus = this.setFirstFocus.bind(this);
        this.setLastFocus = this.setLastFocus.bind(this);
    }

    setFirstFocus(state)
    {
        this.setState({ firstFocus: state });
    }

    setLastFocus(state)
    {
        this.setState({ lastFocus: state });
    }

    render()
    {
        return (
            <div id="contact" className="section section-contact-us text-center">
                <Container>
                    <h2 className="title">Искате да работите с нас?</h2>
                    <p className="description" style={{fontWeight: "inherit"}}>Свържете се с нас</p>
                    <br/>
                    {<CardComponent />}
                </Container>
            </div>
        );
    }
}

export default Contact;